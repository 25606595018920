export function slickSliderJustifyButton() {
    console.log('justifybutn')
    const main_visual_button = $('.js-mv--button');
    // console.log(main_visual_button.width())
    // console.log(main_visual_button.length)

    function setButtonPosition(btnElm){
    btnElm.each(function () {
      let object_harfwidth = $(this).outerWidth() / 2;
      let window_harfwiidth = $(window).width() / 2;
      let display_position = window_harfwiidth - object_harfwidth;
      // console.log(i,elm.width())
      console.log('this:',$(this).outerWidth());
      console.log('window:',$(window).width());

      console.log('display:',display_position);
      $(this).css({
        'margin-left': 0,
        left: display_position  + 'px',
      });
    });

    }

    $(window).on('resize',function(){
        setButtonPosition(main_visual_button);
    })

}
export function slickSlider(){
      $('.slickWrap').slick({
        // dots: true,
        autoplay: true,
        autoplaySpeed: 4000,
      });

}

export function setActiveBackground (){
}

function textOnThumbs(){
  let thumbs = $('.swiperThumbs .swiper-slide');
  thumbs.each(function(index){
    const text = $(this).find('img').attr('data-overtext');
    if(text){
      $(this).append(`<p class="undertext">${text.replace(/\\n/g,"<br>")}</p>`)
      // console.log(text);
      // $(this).prepend(
      //   `<span class="overtext"><span class="overtext__inner">${text.replace(/\\n/g,"<br>")}</span></span>`
      // );
    }
  })
}


export function swiperFullSlider(){
  var thumbSwiper = new Swiper('.swiperThumbs', {
    // loop: true,
    // autoplay: {
    //   delay: 600
    // },
    spaceBetween: 35,
    slidesPerView: 4,
    freeMode: true,
    watchSlidesProgress: true,
    centerInsufficientSlides: true,
    threshold: 10,
    // simulateTouch: true,
    // draggable: false,
    // slideToClickedSlide: true,
    // touchStartForcePreventDefault	: false,
    // shortSwipes: false,
    // touchReleaseOnEdges: true,
    // preventClicks: false,
    // preventClicksPropagation: false,
    // slideToClickedSlide: true,

    on: {
      init: function(){
        console.log("init")
        textOnThumbs();
      }
    }
  });
  var mainSwiper = new Swiper('.swiperMain', {
    spaceBetween: 0,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    autoplay: {
      delay: 6000
    },
    loop: true,
    // breakpoints: {
    //   767: {
    //     // autoplay: {
    //     //   delay: 600
    //     // },
    //     loop: true,
    //   }

    // },
    thumbs: {
      swiper: thumbSwiper,
    },
  });
}

export function swiperSlider() {
const swiper = new Swiper('.swiper', {
  // Optional parameters
  // direction: 'vertical',
  slidesPerView: 'auto',
  centeredSlides: true,
  // slidesPerView: 2,
  slidesPerGroup: 1,
  // autoplay: {
  //   delay: 6000
  // },
  loop: true,
  loopFillGroupWithBlank: true,

  // If we need pagination
  pagination: {
    el: '.swiper-pagination',
  },

  // Navigation arrows
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  effect: 'slide',

  // And if we need scrollbar
  scrollbar: {
    el: '.swiper-scrollbar',
  },
  breakpoints: {
    374: {
      spaceBetween: 0
    },
  },
});


  // const disable_anker = $('.swiper-slide-duplicate a');
  // console.log(disable_anker);

  // function setBackgroundImg(){
  //   const active_slide_image = $('.swiper-slide-active img').attr('src');
  //   // console.log(active_slide_image);
    
  //   $('.hero-wrap')
  //     .css({
  //       'background-image': 'url(' + active_slide_image + ')',
  //     });

  // }
  // swiper.on('transitionEnd', function () {
  //   console.log('change!');
  //   setBackgroundImg();
  // });
  // swiper.on('afterInit', function(){
  //   setBackgroundImg();
  // })

}

