import { topOriginal, toptabs } from './top/original';
import { popEyeInserted } from './top/original';
import { openModalVideo } from './top/modalopenvideo';

import {
  swiperSlider,
  slickSlider,
  slickSliderJustifyButton,
  swiperFullSlider,
} from './top/mainvisual';

topOriginal();
toptabs();
// popEyeInserted();

// slickSliderJustifyButton();
// slickSlider();
// swiperSlider();
swiperFullSlider();

openModalVideo();