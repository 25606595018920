export function toptabs(){
  // top tabs

  var tablabelpath = '.topnews--tablabels .tablabel';
  var tabcontentpath = '.topnews--tabcontents .tabcontent';

  $(tablabelpath).click(function () {
    var index = $(tablabelpath).index(this);
    $(tablabelpath).removeClass('active');
    $(this).addClass('active');
    $(tabcontentpath).removeClass('show').eq(index).addClass('show');
  });
  // console.log(tablabelpath);
}
export function popEyeInserted(){
  // check ispopeye article

  var popeyeArticle = $('.ispopeye');
  let baseSelector = $('.newsroom__body');

  // Popeye flag function
  var haspopeys = false;
  if (popeyeArticle.length > 0) {
    haspopeys = true;
  }
  if (haspopeys) {
    insertPopEyeArticle();
  }

  // console.log("popyed")

  function insertPopEyeArticle() {
    console.log(popeyeArticle)
    baseSelector.after(
      '<div class="info-popeyed for-mobile" style="border: solid 1px red"><div class="info-list__inner"></div></div>'
    );
    $('.ispopeye').each(function (index, elm) {
      $(this).clone(true).appendTo('.info-popeyed > .info-list__inner');
    });
    $('#info-all .ispopeye').addClass('for-pc');
  }
}

export function scrollDown(){
        $('.mainvidual').append('<span class="mainvidual--scrolldown"></span>');
        var scrolldown = $('.mainvidual--scrolldown');
        var scrolldown_position = $('.top-products').offset();
        // console.log(scrolldown_position);
        scrolldown.on('click', function () {
          $('html,body').animate(
            { scrollTop: scrolldown_position.top },
            1500,
            'easeInOutCubic'
          );
        });
}

export function topOriginal(){
    $(function () {
      var width = window.innerWidth;
      var scw = width - document.body.clientWidth;

      $('.info-tab__item').on('click', function () {
        var listName = $(this).attr('data-list');

        $('.info-tab__item').removeClass('open');
        $('.info-list').removeClass('open');

        $(this).addClass('open');
        $('#info-' + listName).addClass('open');
      });
      $('.info-list__title').on('click', function () {
        $(this).toggleClass('open');
        $(this)
          .parent()
          .find('.info-list__inner')
          .slideToggle(300, 'easeOutCubic');
      });

      $(window).on('resize', function () {
        if (width == window.innerWidth) {
          return;
        }

        width = window.innerWidth;

        if (width - scw >= 768) {
          $('.info-list__title').removeClass('open');
          $('.info-list__inner').attr('style', '');
        }
      });

      // maividual lazy load

      var loadMainvidual = function (callback) {
        // showImage();
        // callback();
      };
      var showImage = function () {
        $('.for-pc .vidualContent').css({
          transition: 'all',
          opacity: 1,
          display: 'block',
        });
      };
      var enableSlickSlider = function () {
        $('.mainVidual').slick({
          autoplay: true,
          dots: true,
          centerMode: true,
          variableWidth: true,
          speed: 900,
          arrows: false,
          autoplaySpeed: 4000,
          lazyLoad: 'progressive',
          // fade: true,
          // prevArrow : '<button type="button" class="slick-prev2">&gt;</button>',
          // nextArrow : '<button type="button" class="slick-next">Next</button>'
        });
      };

      loadMainvidual(enableSlickSlider);





    });

}