// import * as from 'modaal';
// import * as modaal from 'modaal';
// import {modaal} from 'modaal';

export function openModalVideo(){
    // console.log("test");

    let video_elm = $('.modal_video');
    let iframe_elm = $('.modal_iframe');

    if(video_elm){
        video_elm.modaal({
            type: 'video'
        });
    }
    if(iframe_elm){
        let aspect_width = 800;
        let aspect_height = (aspect_width /16 ) * 9
        iframe_elm.modaal({
            type: 'iframe',
            width: aspect_width,
            height: aspect_height,
            
        });
    }
    
}